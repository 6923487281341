import "./App.css";
import ThemeProvider from "./global/ThemeProvider";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import GlobalLayout from "./components/GlobalLayout";
import ErrorPage from "./pages/error/ErrorPage";
import Home from "./pages/home/Home";
import Products from "./pages/products/Products";
import AboutUs from "./pages/about-us/AboutUs";
import ContactUs from "./pages/contact/ContactUs";
import WhyTextaj from "./pages/why-textaj/WhyTextaj";
import ProductDetails from "./pages/products/ProductDetails";
import Blogs from "./pages/blogs/Blogs";
import BlogDetails from "./pages/blogs/BlogDetails";

function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<GlobalLayout />}>
            <Route index element={<Home />} />
            <Route path="*" element={<ErrorPage />} />
            <Route path="products">
              <Route index element={<Products />} />
              <Route path=":id" element={<ProductDetails />} />
              <Route path="category/:categoryId" element={<Products />} />
              <Route
                path="category/:categoryId/subcategory/:subcategoryId"
                element={<Products />}
              />
            </Route>
            <Route path="blogs">
              <Route index element={<Blogs />} />
              <Route path=":slug" element={<BlogDetails />} />
              <Route path="category/:categoryId" element={<Blogs />} />
            </Route>
            <Route path="about-us" element={<AboutUs />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="why-textaj" element={<WhyTextaj />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
