import { Button, Carousel, Image, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { API_COMMON } from "../../utils/api";
const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const heroCarouselItems = [
  {
    id: 1,
    img: "/assets/images/Banner/1.png",
    title: "Demo Title 1",
    subTitle: "Demo Sub title 1",
    showProductsButton: true,
  },
  {
    id: 2,
    img: "/assets/images/Banner/2.png",
    title: "Demo Title 2",
    subTitle: "Demo Sub title 2",
    showProductsButton: true,
  },
  {
    id: 3,
    img: "/assets/images/Banner/3.png",
    title: "Demo Title 3",
    subTitle: "Demo Sub title 3",
    showProductsButton: true,
  },
  {
    id: 4,
    img: "/assets/images/Banner/4.png",
    title: "Demo Title 4",
    subTitle: "Demo Sub title 4",
    showProductsButton: true,
  },
];
const HeroCarousel = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="hero-carousel py-10 px-5">
        <div className="flex flex-col gap-5">
          <h2 className="text-5xl md:text-7xl font-bold text-center">
            Textaj Sourcing International
          </h2>
          <h1 className="text-center font-semibold text-sm uppercase">
            Sustainable Apparel Solution
          </h1>
        </div>
        <div className="w-full flex gap-2 relative my-10">
          <div className=" hidden md:flex flex-1 w-full text-center">
            <Image
              className="w-full h-full object-fill aspect-[9/12]"
              preview={true}
              src={`${API_COMMON}/assets/images/new/1.jpg`}
              alt="company image"
              width={"100%"}
            />
          </div>

          <div className="hidden sm:flex flex-1 w-full text-center">
            <Image
              className="w-full h-full object-fill aspect-[9/12]"
              preview={true}
              src={`${API_COMMON}/assets/images/new/2.jpg`}
              alt="company image"
              width={"100%"}
            />
          </div>

          <div className="  hidden lg:flex flex-1 w-full text-center">
            <Image
              className="w-full h-full object-fill aspect-[9/12]"
              preview={true}
              src={`${API_COMMON}/assets/images/common/14.jpg`}
              alt="company image"
              width={"100%"}
            />
          </div>

          <div className="flex-1 w-full text-center">
            <Image
              className="w-full h-full object-fill aspect-[9/12]"
              preview={true}
              src={`${API_COMMON}/assets/images/new/3.jpg`}
              alt="company image"
              width={"100%"}
            />
          </div>

          <div className="flex-1 w-full text-center">
            <Image
              className="w-full h-full object-fill aspect-[9/12]"
              preview={true}
              src={`${API_COMMON}/assets/images/common/17.jpg`}
              alt="company image"
              width={"100%"}
            />
          </div>
        </div>
        {/* <Carousel
          arrows
          infinite={true}
          dots={true}
          autoplay
          autoplaySpeed={4500}
        >
          {heroCarouselItems.map((item) => (
            <div
              key={item.id}
              className="h-[calc(100vh-64px)]  w-full !grid grid-cols-1 md:grid-cols-2 gap-5 px-16 "
            >
              <div className="h-full w-full flex items-start justify-center flex-col gap-5 pl-16">
                <h3 className="text-3xl">{item.title}</h3>
                <h4>{item.subTitle}</h4>
                {item.showProductsButton && (
                  <Button
                    type="primary"
                    onClick={() => navigate("/products", { replace: true })}
                  >
                    {" "}
                    GO TO PRODUCTS
                  </Button>
                )}
              </div>
              <div
                className="w-full h-[90%] bg-center bg-contain bg-no-repeat "
                style={{ backgroundImage: `url(${item.img})` }}
              >
              </div>
            </div>
          ))}
        </Carousel> */}
      </div>
    </>
  );
};

export default HeroCarousel;
