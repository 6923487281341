import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { BASE_URL_IMAGE } from "../utils/api";

const ProductCard = ({ id, name, img, category, fromProduct }) => {
  return (
    <Link to={`/products/${id}`} className="block">
      <div
        className={`group relative border bg-white rounded-lg overflow-hidden transition-all duration-300 hover:shadow-lg ${
          !fromProduct ? "mx-2" : ""
        }`}
      >
        {/* Image Container */}
        <div className="aspect-square w-full overflow-hidden bg-gray-100 lg:aspect-none group-hover:opacity-90 relative">
          <Image
            alt={name}
            src={`${BASE_URL_IMAGE}/${img}`}
            className="h-[250px] w-full object-contain object-center p-4"
            preview={false}
          />
        </div>

        {/* Product Info */}
        <div className="p-4 space-y-2">
          {/* Category */}
          <div className="text-xs text-gray-500 uppercase tracking-wider">
            {category}
          </div>

          {/* Name */}
          <h3 className="text-sm font-medium text-gray-900 group-hover:text-blue-600 line-clamp-2">
            {name}
          </h3>
        </div>

        {/* Quick View Overlay - Optional */}
        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-5 transition-all duration-300 pointer-events-none" />
      </div>
    </Link>
  );
};

export default ProductCard;
