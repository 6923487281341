import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Breadcrumb, Tag, Skeleton, Empty } from "antd";
import { BASE_URL_IMAGE, blogApi } from "../../utils/api";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";

const BlogDetails = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await blogApi.getBySlug(slug);
        if (response.data?.data) {
          setBlog(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching blog:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [slug]);

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <Skeleton active />
      </div>
    );
  }

  if (!blog) {
    return (
      <div className="container mx-auto px-4 py-8">
        <Empty description="Blog not found" />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 my-6">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item>
          <a onClick={() => navigate("/blogs")}>Blogs</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a onClick={() => navigate(`/blogs/category/${blog.category.id}`)}>
            {blog.category.name}
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{blog.title}</Breadcrumb.Item>
      </Breadcrumb>

      <Card className="shadow-lg">
        {blog.image_url && (
          <img
            src={`${BASE_URL_IMAGE}/${blog.image_url}`}
            alt={blog.title}
            className="w-full h-[400px] object-cover rounded-t-lg mb-6"
          />
        )}

        <h1 className="text-4xl font-bold mb-4">{blog.title}</h1>

        <div className="flex flex-wrap gap-4 mb-6 text-gray-500">
          <span className="flex items-center gap-2">
            <CalendarOutlined />
            {new Date(blog.published_at).toLocaleDateString()}
          </span>
          {blog.reading_time && (
            <span className="flex items-center gap-2">
              <ClockCircleOutlined />
              {blog.reading_time} min read
            </span>
          )}
          {blog.author_name && (
            <span className="flex items-center gap-2">
              <UserOutlined />
              {blog.author_name}
            </span>
          )}
          <span className="flex items-center gap-2">
            Category: {blog.category.name}
          </span>
        </div>

        <div className="prose max-w-none mb-6">
          <div dangerouslySetInnerHTML={{ __html: blog.content }} />
        </div>

        {blog.tags && (
          <div className="flex flex-wrap gap-2 mt-6">
            {blog.tags.split(",").map((tag) => (
              <Tag key={tag}>{tag.trim()}</Tag>
            ))}
          </div>
        )}
      </Card>
    </div>
  );
};

export default BlogDetails;
