import React, { useEffect, useMemo, useState } from "react";
import { Button, Carousel } from "antd";
import { useMediaQuery } from "react-responsive";
import ProductCard from "../ProductCard";
import { useNavigate } from "react-router-dom";
import {
  fetchCategories,
  fetchProductsByCategory,
  fetchFeaturedProducts,
} from "../../utils/api";

const FeaturedProducts = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState("all");
  const isMediumScreen = useMediaQuery({ maxWidth: 1023 });
  const isSmallScreen = useMediaQuery({ maxWidth: 834 });

  useEffect(() => {
    const loadCategories = async () => {
      const categoryData = await fetchCategories();
      setCategories([{ id: "all", category: "all", key: "all" }, ...categoryData]);
    };

    const loadFeaturedProducts = async () => {
      const productData = await fetchFeaturedProducts();
      setProducts(productData);
    };

    loadCategories();
    loadFeaturedProducts();
  }, []);

  useEffect(() => {
    const loadProducts = async () => {
      if (filter === "all") {
        const productData = await fetchFeaturedProducts();
        setProducts(productData);
      } else {
        const productData = await fetchFeaturedProducts(filter);
        setProducts(productData);
      }
    };

    loadProducts();
  }, [filter]);

  return (
    <div className="bg-slate-50 py-16 featured-product dark:bg-slate-900">
      <div className="w-[90%] mx-auto">
        <h2 className="font-bold text-4xl text-slate-900 dark:text-white text-center">
          Featured Products
        </h2>
        <div className="w-full flex items-center justify-center">
          <div className="flex items-center gap-2 my-10">
            {categories.length > 0 &&
              categories.map((item) => (
                <Button
                  key={item.key}
                  type={item.key === filter ? "primary" : "default"}
                  onClick={() => setFilter(item.key)}
                  className="uppercase text-sm font-semibold"
                >
                  {item.category}
                </Button>
              ))}
          </div>
        </div>
        <Carousel
          arrows
          infinite={true}
          dots={false}
          autoplaySpeed={4000}
          slidesPerRow={isSmallScreen ? 2 : isMediumScreen ? 3 : 5}
          slidesToScroll={1}
        >
          {products.map((product) => (
            <div
              key={product.id}
              className="w-full flex items-center gap-4 overflow-hidden"
            >
              <ProductCard
                id={product.id}
                key={product.id}
                name={product.name}
                img={product.image_url}
                category={product.category.name}
              />
            </div>
          ))}
        </Carousel>
        <div className="flex items-center justify-center my-10">
          <Button
            type="primary"
            onClick={() => navigate("/products", { replace: true })}
          >
            VIEW ALL PRODUCT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProducts;
