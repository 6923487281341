import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Image, Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import apiService from "../../utils/api";
import { BASE_URL_IMAGE } from "../../utils/api";
import { HomeOutlined } from "@ant-design/icons";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await apiService.get(`/products/${id}`);
        setProduct(response.data.data || {});
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) {
    return (
      <div className="w-full text-center py-20 text-lg font-semibold text-gray-600">
        Loading...
      </div>
    );
  }

  if (!product) {
    return (
      <div className="w-full text-center py-20">
        <p className="text-lg font-bold text-gray-600">Product not found</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 my-12">
      {/* Breadcrumb */}
      <Breadcrumb
        className="mb-8"
        items={[
          {
            title: (
              <Link to="/">
                <HomeOutlined />
              </Link>
            ),
          },
          {
            title: <Link to="/products">Products</Link>,
          },
          {
            title: product.category.name,
          },
          {
            title: product.name,
          },
        ]}
      />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Image Section */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <Image
            src={`${BASE_URL_IMAGE}/${product.image_url}`}
            alt={product.name}
            className="w-full object-contain"
            style={{ height: "500px" }}
          />
        </div>

        {/* Product Information */}
        <div className="space-y-6">
          {/* Category & Subcategory */}
          <div className="space-y-1">
            <div className="text-sm text-gray-500 uppercase tracking-wider">
              {product.category.name}
              {product.subcategory && ` / ${product.subcategory.name}`}
            </div>
          </div>

          {/* Product Name */}
          <h1 className="text-3xl font-bold text-gray-900">{product.name}</h1>

          {/* Description */}
          {product.description && (
            <div className="prose max-w-none">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                Description
              </h3>
              <p className="text-gray-600">{product.description}</p>
            </div>
          )}

          {/* Tags */}
          {product.tags && (
            <div className="flex flex-wrap gap-2">
              {product.tags.map((tag, index) => (
                <span
                  key={index}
                  className="px-3 py-1 border border-[#fcb99c] bg-gray-100 text-gray-600 rounded-full text-sm"
                >
                  {tag}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
