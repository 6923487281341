import {
  EnvironmentFilled,
  FacebookOutlined,
  LinkedinOutlined,
  MailFilled,
  PhoneFilled,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { companyApi } from "../utils/api";

const PageFooter = () => {
  const navigate = useNavigate();
  const [companyInfo, setCompanyInfo] = useState(null);

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await companyApi.getInfo();
        setCompanyInfo(response.data.data);
      } catch (error) {
        console.error("Error fetching company info:", error);
      }
    };

    fetchCompanyInfo();
  }, []);

  return (
    <div className="w-full">
      <div className="w-full bg-slate-700 dark:bg-slate-800 text-white py-10">
        <div className="md:w-2/3 px-5 mx-auto flex flex-col md:flex-row md:items-center md:justify-between gap-5">
          <div className="flex flex-col gap-3">
            <h2 className="font-semibold text-xl">Our Company</h2>
            <ul>
              <li
                className="hover:underline hover:cursor-pointer"
                onClick={() => navigate("/about-us", { replace: true })}
              >
                About us
              </li>
              <li
                className="hover:underline hover:cursor-pointer"
                onClick={() => navigate("/products", { replace: true })}
              >
                Products
              </li>
              <li
                className="hover:underline hover:cursor-pointer"
                onClick={() => navigate("/why-textaj", { replace: true })}
              >
                Why Textaj
              </li>
            </ul>
          </div>

          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-3">
              {/* <h2 className="font-semibold text-xl">
                  {companyInfo.company_name}
                </h2>
                <div className="flex items-center gap-3">
                  <PhoneFilled /> {companyInfo.phone}
                </div>
                <div className="flex items-center gap-3">
                  <MailFilled /> {companyInfo.email}
                </div>
                <div className="flex items-center gap-3">
                  <EnvironmentFilled />
                  {companyInfo.address || ""}
                </div> */}
              <h2 className="font-semibold text-xl">Mosiur Rahaman</h2>
              <div className="flex items-center gap-3">
                <MailFilled /> mosiur@textaj.com
              </div>
              <div className="flex items-center gap-3">
                <PhoneFilled /> 01618709971
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <h2 className="font-semibold text-xl">CEO</h2>
              <div className="flex items-center gap-3">
                <MailFilled /> CEO@textaj.com
              </div>
              <div className="flex items-center gap-3">
                <PhoneFilled /> 01711688621
              </div>
            </div>
            {companyInfo && companyInfo.CompanyAdditionalInfo?.[0] && (
              <div className="flex items-center gap-5">
                {companyInfo.CompanyAdditionalInfo[0].facebook_link && (
                  <a
                    href={companyInfo.CompanyAdditionalInfo[0].facebook_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="size-10 border-2 border-white rounded-full flex items-center justify-center hover:cursor-pointer hover:bg-blue-700 hover:text-white"
                  >
                    <FacebookOutlined style={{ fontSize: 20 }} />
                  </a>
                )}
                {companyInfo.CompanyAdditionalInfo[0].linkedin_link && (
                  <a
                    href={companyInfo.CompanyAdditionalInfo[0].linkedin_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="size-10 border-2 border-white rounded-full flex items-center justify-center hover:cursor-pointer hover:bg-sky-950 hover:text-white"
                  >
                    <LinkedinOutlined style={{ fontSize: 20 }} />
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full bg-slate-900 py-5 text-white font-semibold text-center">
        &copy; {new Date().getFullYear()} |{" "}
        {companyInfo?.company_name || "Textaj Sourcing International"}
      </div>
    </div>
  );
};

export default PageFooter;
