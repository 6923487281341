import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Image } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { categoryApi } from "../utils/api";

const Sidebar = ({ onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuData, setMenuData] = useState([]);

  const menuItems = [
    { key: "home", label: "Home", path: "/" },
    { key: "products", label: "Products", path: "/products" },
    { key: "blogs", label: "Blogs", path: "/blogs" },
    { key: "about-us", label: "About Us", path: "/about-us" },
    { key: "contact", label: "Contact", path: "/contact" },
    { key: "whytextaj", label: "Why Textaj", path: "/why-textaj" },
  ];

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await categoryApi.getMenu();
        if (Array.isArray(response.data.data)) {
          setMenuData(response.data.data);
        } else {
          setMenuData([]);
        }
      } catch (error) {
        console.error("Error fetching menu:", error);
      }
    };

    fetchMenuData();
  }, []);

  const menu = (
    <Menu mode="vertical" className="dark:bg-slate-800">
      {(() => {
        if (!Array.isArray(menuData)) {
          return null;
        }

        return menuData.map((item) => {
          if (!item) return null;

          if (item.children && item.children.length > 0) {
            return (
              <Menu.SubMenu
                key={item.key}
                title={item.label}
                className="dark:text-white"
              >
                <Menu.Item
                  key={`${item.key}_all`}
                  onClick={() => {
                    navigate(`/products/category/${item.key}`, {
                      replace: true,
                    });
                    onClose();
                  }}
                  className="dark:text-white dark:hover:!text-primary"
                >
                  All {item.label}
                </Menu.Item>
                <Menu.Divider />
                {item.children.map((child) => (
                  <Menu.Item
                    key={child.key}
                    onClick={() => {
                      navigate(
                        `/products/category/${item.key}/subcategory/${child.key}`,
                        { replace: true }
                      );
                      onClose();
                    }}
                    className="dark:text-white dark:hover:!text-primary"
                  >
                    {child.label}
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          }

          return (
            <Menu.Item
              key={item.key}
              onClick={() => {
                navigate(`/products/category/${item.key}`, { replace: true });
                onClose();
              }}
              className="dark:text-white dark:hover:!text-primary"
            >
              {item.label}
            </Menu.Item>
          );
        });
      })()}
    </Menu>
  );

  const getCurrentSelectedKey = () => {
    const matchedItem = menuItems.find((item) =>
      location.pathname.startsWith(item.path)
    );
    return matchedItem ? matchedItem.key : "home";
  };

  return (
    <div className="h-full dark:bg-slate-800 flex flex-col">
      <div className="flex items-center justify-center p-4 border-b dark:border-slate-700">
        <Image
          src="/assets/logo.png"
          width={150}
          height={40}
          alt="logo"
          preview={false}
          className="transition-transform duration-300 hover:scale-105"
        />
      </div>

      <Menu
        mode="inline"
        defaultSelectedKeys={[getCurrentSelectedKey()]}
        className="w-full flex-1 border-none dark:bg-slate-800"
      >
        <Menu.Item
          key="home"
          className="!text-base font-medium hover:!text-primary border-r-2 border-transparent hover:!border-primary transition-all duration-300 dark:text-white dark:hover:!text-primary"
          onClick={() => {
            navigate("/", { replace: true });
            onClose();
          }}
        >
          Home
        </Menu.Item>
        <Menu.Item
          key="about-us"
          className="!text-base font-medium hover:!text-primary border-r-2 border-transparent hover:!border-primary transition-all duration-300 dark:text-white dark:hover:!text-primary"
          onClick={() => {
            navigate("/about-us", { replace: true });
            onClose();
          }}
        >
          About
        </Menu.Item>
        <Dropdown overlay={menu} trigger={["click"]} className="w-full">
          <Menu.Item
            key="products"
            className="!text-base font-medium hover:!text-primary border-r-2 border-transparent hover:!border-primary transition-all duration-300 dark:text-white dark:hover:!text-primary"
          >
            Products <DownOutlined className="text-xs ml-1" />
          </Menu.Item>
        </Dropdown>
        <Menu.Item
          key="blogs"
          className="!text-base font-medium hover:!text-primary border-r-2 border-transparent hover:!border-primary transition-all duration-300 dark:text-white dark:hover:!text-primary"
          onClick={() => {
            navigate("/blogs", { replace: true });
            onClose();
          }}
        >
          Blogs
        </Menu.Item>
        <Menu.Item
          key="whytextaj"
          className="!text-base font-medium hover:!text-primary border-r-2 border-transparent hover:!border-primary transition-all duration-300 dark:text-white dark:hover:!text-primary"
          onClick={() => {
            navigate("/why-textaj", { replace: true });
            onClose();
          }}
        >
          Why Textaj
        </Menu.Item>
        <Menu.Item
          key="contact"
          className="!text-base font-medium hover:!text-primary border-r-2 border-transparent hover:!border-primary transition-all duration-300 dark:text-white dark:hover:!text-primary"
          onClick={() => {
            navigate("/contact", { replace: true });
            onClose();
          }}
        >
          Contact
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default Sidebar;
