import {
  DownOutlined,
  MenuOutlined,
  MoonFilled,
  SunOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Dropdown, Image, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { setCurrentTheme, setTheme } from "../redux/theme/themeSlice";
import { themes } from "../global/ThemeProvider";
import { useMediaQuery } from "react-responsive";
import Sidebar from "./Sidebar";
import { categoryApi } from "../utils/api";

const PageHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);

  const isMediumScreen = useMediaQuery({ maxWidth: 1023 });
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [collapsed, setCollapsed] = useState(false);
  const [menuData, setMenuData] = useState([]);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setCollapsed(false);
  };

  useEffect(() => {
    setCollapsed(isMediumScreen);
  }, [isMediumScreen]);

  useEffect(() => {
    const handleResize = () => {
      setCollapsed(isMediumScreen);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMediumScreen]);

  const toggleSider = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await categoryApi.getMenu();

        if (Array.isArray(response.data.data)) {
          setMenuData(response.data.data);
        } else {
          console.warn("Expected an array but got:", response.data.data);
          setMenuData([]); // Set to an empty array if the data is not an array
        }
      } catch (error) {
        console.error("Error fetching menu:", error);
      }
    };

    fetchMenuData();
  }, []);

  const menuItems = [
    { key: "home", label: "Home", path: "/" },
    { key: "products", label: "Products", path: "/products" },
    { key: "blogs", label: "Blogs", path: "/blogs" },
    { key: "about-us", label: "About Us", path: "/about-us" },
    { key: "contact", label: "Contact", path: "/contact" },
    { key: "whytextaj", label: "Why Textaj", path: "/why-textaj" },
  ];

  const menu = (
    <Menu mode="vertical">
      {(() => {
        if (!Array.isArray(menuData)) {
          console.warn("menuData is not an array:", typeof menuData);
          return null;
        }

        return menuData.map((item) => {
          if (!item) return null;

          if (item.children && item.children.length > 0) {
            return (
              <Menu.SubMenu key={item.key} title={item.label}>
                {/* Category-level "All" item */}
                <Menu.Item
                  key={`${item.key}_all`}
                  onClick={() =>
                    navigate(`/products/category/${item.key}`, {
                      replace: true,
                    })
                  }
                >
                  All {item.label}
                </Menu.Item>

                {/* Divider */}
                <Menu.Divider />

                {/* Subcategory items */}
                {item.children.map((child) => (
                  <Menu.Item
                    key={child.key}
                    onClick={() =>
                      navigate(
                        `/products/category/${item.key}/subcategory/${child.key}`,
                        { replace: true }
                      )
                    }
                  >
                    {child.label}
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          }

          // If no children, render as a simple menu item
          return (
            <Menu.Item
              key={item.key}
              onClick={() =>
                navigate(`/products/category/${item.key}`, { replace: true })
              }
            >
              {item.label}
            </Menu.Item>
          );
        });
      })()}
    </Menu>
  );
  const getCurrentSelectedKey = () => {
    const matchedItem = menuItems.find((item) =>
      matchPath(location.pathname, item.path)
    );
    return matchedItem ? matchedItem.key : "home"; // Default to "home" if no match found
  };

  const changeTheme = (sTheme) => {
    const mode = sTheme === "default_light" ? "light" : "dark";
    dispatch(setTheme(mode));
    dispatch(setCurrentTheme(themes[sTheme]));
  };

  return (
    <div className="fixed top-0 left-0 right-0 z-[9999] bg-white dark:bg-slate-800 shadow-md transition-all duration-300">
      <div className="container mx-auto px-4 bg-inherit">
        <div className="flex items-center justify-between h-20">
          {/* Logo Section */}
          <div className="flex items-center">
            {theme === "dark" ? (
              <Image
                src="/assets/logo.png"
                width={150}
                height={80}
                alt="logo"
                preview={false}
                className="transition-transform duration-300 hover:scale-105"
              />
            ) : (
              <Image
                src="/assets/logo.png"
                width={150}
                height={80}
                alt="logo"
                preview={false}
                className="transition-transform duration-300 hover:scale-105"
              />
            )}
          </div>

          {/* Navigation Menu */}
          {!isMediumScreen && (
            <div className="flex-1 flex items-center justify-center max-w-3xl h-full bg-inherit">
              <Menu
                mode="horizontal"
                defaultSelectedKeys={[getCurrentSelectedKey()]}
                className="w-full border-none bg-inherit flex items-center justify-center h-full"
                disabledOverflow
              >
                <Menu.Item
                  key="home"
                  className="!px-6 !text-base font-medium hover:!text-primary border-b-2 border-transparent hover:!border-primary transition-all duration-300 flex items-center h-full dark:text-white dark:hover:!text-primary"
                  onClick={() => navigate("/", { replace: true })}
                >
                  Home
                </Menu.Item>
                <Menu.Item
                  key="about-us"
                  className="!px-6 !text-base font-medium hover:!text-primary border-b-2 border-transparent hover:!border-primary transition-all duration-300 flex items-center h-full dark:text-white dark:hover:!text-primary"
                  onClick={() => navigate("/about-us", { replace: true })}
                >
                  About
                </Menu.Item>
                <Dropdown
                  overlay={menu}
                  trigger={["hover"]}
                  className="!px-6 !text-base font-medium flex items-center h-full dark:text-white"
                >
                  <Menu.Item
                    key="products"
                    className="!text-base font-medium hover:!text-primary border-b-2 border-transparent hover:!border-primary transition-all duration-300 flex items-center h-full dark:text-white dark:hover:!text-primary"
                    onClick={() => navigate("/products", { replace: true })}
                  >
                    Products <DownOutlined className="text-xs ml-1" />
                  </Menu.Item>
                </Dropdown>
                <Menu.Item
                  key="blogs"
                  className="!px-6 !text-base font-medium hover:!text-primary border-b-2 border-transparent hover:!border-primary transition-all duration-300 flex items-center h-full dark:text-white dark:hover:!text-primary"
                  onClick={() => navigate("/blogs", { replace: true })}
                >
                  Blogs
                </Menu.Item>
                <Menu.Item
                  key="whytextaj"
                  className="!px-6 !text-base font-medium hover:!text-primary border-b-2 border-transparent hover:!border-primary transition-all duration-300 flex items-center h-full dark:text-white dark:hover:!text-primary"
                  onClick={() => navigate("/why-textaj", { replace: true })}
                >
                  Why Textaj
                </Menu.Item>
                <Menu.Item
                  key="contact"
                  className="!px-6 !text-base font-medium hover:!text-primary border-b-2 border-transparent hover:!border-primary transition-all duration-300 flex items-center h-full dark:text-white dark:hover:!text-primary"
                  onClick={() => navigate("/contact", { replace: true })}
                >
                  Contact
                </Menu.Item>
              </Menu>
            </div>
          )}

          {/* Right Section - Theme Toggle & Mobile Menu */}
          <div className="flex items-center gap-4">
            {/* <Button
              onClick={() =>
                changeTheme(theme === "dark" ? "default_light" : "default_dark")
              }
              type="text"
              className="flex items-center justify-center w-15 h-15 rounded-full hover:bg-gray-100 dark:hover:bg-slate-700 dark:text-white transition-all duration-300"
              icon={
                theme === "dark" ? (
                  <SunOutlined className="text-lg" />
                ) : (
                  <MoonFilled className="text-lg" />
                )
              }
            /> */}

            {isMediumScreen && (
              <Button
                type="text"
                className="flex items-center justify-center w-10 h-10 rounded-full hover:bg-gray-100 dark:hover:bg-slate-700 dark:text-white"
                onClick={showDrawer}
                icon={<MenuOutlined className="text-lg" />}
              />
            )}
          </div>

          {/* Mobile Menu Drawer */}
          {isMediumScreen && open && (
            <Drawer
              placement={placement}
              width={300}
              onClose={onClose}
              open={open}
              maskClosable={false}
              className="dark:bg-slate-800"
              bodyStyle={{ padding: 0 }}
            >
              <Sidebar onClose={onClose} />
            </Drawer>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
