import { Menu, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import ProductCard from "../../components/ProductCard";
import { useMediaQuery } from "react-responsive";
import { categoryApi, productApi } from "../../utils/api";
import { useParams, useNavigate, useLocation } from "react-router-dom";

const Products = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [total, setTotal] = useState(0);
  const [products, setProducts] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);

  const { categoryId, subcategoryId } = useParams();
  const location = useLocation();
  const isMediumScreen = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();

  // Determine if sidebar should be shown based on the current route
  useEffect(() => {
    // Show sidebar only for main products page or category/subcategory pages
    const shouldShowSidebar = location.pathname === '/products' 
    // || 
    //   location.pathname.includes('/category') ||
    //   location.pathname.includes('/subcategory');
    
    setShowSidebar(shouldShowSidebar);
  }, [location.pathname]);

  
  // Handle submenu open/close
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey) {
      setOpenKeys([latestOpenKey]);
    } else {
      setOpenKeys([]);
    }
  };

  // Fetch category menu
  useEffect(() => {
    const fetchCategoryMenu = async () => {
      try {
        const response = await categoryApi.getMenu();
        if (response.data?.data) {
          const transformedMenu = transformMenuData(response.data.data);
          setMenuItems(transformedMenu);
        }
      } catch (error) {
        console.error("Error fetching menu:", error);
      }
    };
    fetchCategoryMenu();
  }, []);

  // Transform menu data to Ant Design Menu format
  const transformMenuData = (data) => {
    if (!Array.isArray(data)) return [];

    return [
      ...data.map((item) => {
        const menuItem = {
          key: item.key,
          label: item.label,
          type: item.type,
        };

        if (item.children && item.children.length > 0) {
          menuItem.children = [
            {
              key: `${item.key}_all`,
              label: `All ${item.label}`,
              onClick: () => handleMenuClick(item.key, "category"),
            },
            {
              type: "divider",
            },
            ...item.children.map((child) => ({
              key: child.key,
              label: child.label,
              onClick: () =>
                handleMenuClick(child.key, "subcategory", item.key),
            })),
          ];
        } else {
          menuItem.onClick = () => handleMenuClick(item.key, "category");
        }

        return menuItem;
      }),
    ];
  };

  // Handle menu item clicks
  const handleMenuClick = async (key, type, parentKey = null) => {
    setCurrentPage(1); // Reset to first page on filter change

    if (key === "all") {
      navigate("/products", { replace: true });
    } else if (type === "category") {
      navigate(`/products/category/${key}`, { replace: true });
    } else if (type === "subcategory" && parentKey) {
      navigate(`/products/category/${parentKey}/subcategory/${key}`, {
        replace: true,
      });
    }
  };

  // Fetch products based on URL parameters
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        let response;

        if (categoryId && subcategoryId) {
          // Fetch products by both category and subcategory (e.g., Sweater/Men)
          response = await productApi.getByCategoryAndSubcategory(
            categoryId,
            subcategoryId,
            currentPage,
            pageSize
          );
        } else if (categoryId) {
          // Fetch products by category only (e.g., all Sweater products)
          response = await productApi.getByCategory(
            categoryId,
            currentPage,
            pageSize
          );
        } else {
          // Fetch all products
          response = await productApi.getAll(currentPage, pageSize);
        }

        if (response.data?.data) {
          const data = response.data.data;
          setProducts(data.products);
          setTotal(data.pagination.total);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [categoryId, subcategoryId, currentPage, pageSize]);

  const onShowSizeChange = (current, size) => {
    setCurrentPage(1);
    setPageSize(size);
  };

  const onChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="w-full product-list py-5">
      <div className={`container mx-auto ${showSidebar ? 'grid grid-cols-1 md:grid-cols-[250px_minmax(250px,1fr)]' : ''} gap-5 my-5`}>
        {showSidebar && (
          <div
            className={`${
              isMediumScreen ? "w-full" : "w-[250px]"
            } place-self-center md:place-self-start`}
          >
            <Menu
              mode={isMediumScreen ? "horizontal" : "inline"}
              inlineCollapsed={isMediumScreen ? false : collapsed}
              defaultSelectedKeys={[subcategoryId || categoryId || "all"]}
              defaultOpenKeys={categoryId ? [categoryId] : []}
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              className="py-10 category-menu"
              style={{
                width: isMediumScreen ? "100%" : 250,
                padding: "5px",
              }}
              items={menuItems}
            />
          </div>
        )}

        <div className="flex items-start justify-center w-full">
          <div className="px-3 w-full">
            {loading ? (
              <div className="w-full text-center py-10 text-lg font-semibold text-gray-600">
                Loading...
              </div>
            ) : products && products.length === 0 ? (
              <div className="w-full text-center py-10">
                <p className="text-lg font-bold text-gray-600">
                  No products found
                </p>
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                {products &&
                  products.length > 0 &&
                  products.map((product) => (
                    <ProductCard
                      key={product.id}
                      id={product.id}
                      name={product.name}
                      img={product.image_url}
                      category={product.category.name}
                      price={product.price}
                      stock={product.stock}
                      fromProduct={true}
                    />
                  ))}
              </div>
            )}
          </div>
        </div>

        <div>&nbsp;</div>
        <div className="w-full flex items-center justify-end">
          {total > 0 && (
            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              onChange={onChange}
              current={currentPage}
              pageSize={pageSize}
              total={total}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Products;
