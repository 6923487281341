import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Pagination, Empty } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL_IMAGE, blogApi } from '../../utils/api';

const { Meta } = Card;

const Blogs = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [blogs, setBlogs] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
    total: 0,
  });
  const [loading, setLoading] = useState(false);

  const fetchBlogs = async (page = 1) => {
    setLoading(true);
    try {
      const response = await blogApi.getAll({
        page,
        pageSize: pagination.pageSize,
        categoryId,
      });
      
      if (response.data?.data) {
        setBlogs(response.data.data.data);
        setPagination({
          ...pagination,
          current: page,
          total: response.data.total,
        });
      }
    } catch (error) {
      console.error('Error fetching blogs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, [categoryId]);

  const handlePageChange = (page) => {
    fetchBlogs(page);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Blog Posts</h1>
      {blogs.length > 0 ? (
        <>
          <Row gutter={[24, 24]}>
            {blogs.map((blog) => (
              <Col xs={24} sm={12} md={8} lg={6} key={blog.id}>
                <Card
                  hoverable
                  cover={
                    blog.image_url && (
                      <img
                        alt={blog.title}
                        src={`${BASE_URL_IMAGE}/${blog.image_url}`}
                        className="h-48 object-cover"
                      />
                    )
                  }
                  onClick={() => navigate(`/blogs/${blog.slug}`)}
                >
                  <Meta
                    title={blog.title}
                    description={
                      <div>
                        <p className="text-gray-500 mb-2">
                          {blog.description ? blog.description.substring(0, 100) : ''}...
                        </p>
                        <div className="flex justify-between items-center text-sm text-gray-400">
                          <span>{blog.category.name}</span>
                          <span>{new Date(blog.published_at).toLocaleDateString()}</span>
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
          <div className="flex justify-center mt-8">
            <Pagination
              current={pagination.current}
              total={pagination.total}
              pageSize={pagination.pageSize}
              onChange={handlePageChange}
            />
          </div>
        </>
      ) : (
        <Empty description="No blogs found" />
      )}
    </div>
  );
};

export default Blogs; 