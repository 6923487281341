import axios from "axios";

const API_BASE_URLL = "http://localhost:5000";
const API_BASE_URL = "https://bo.textaj.com";

export const API_COMMONL = "http://localhost:5000/upload";
export const API_COMMON = "https://bo.textaj.com/upload";

const API_VERSION = "/api/v1";

export const BASE_URL_IMAGE = API_BASE_URL;

const api = axios.create({
  baseURL: `${API_BASE_URL}${API_VERSION}`,
  headers: {
    "Content-Type": "application/json",
  },
});

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 404:
          console.error("Resource not found");
          break;
        case 500:
          console.error("Server error");
          break;
        default:
          console.error("Something went wrong");
          break;
      }
    }
    return Promise.reject(error);
  }
);

// API endpoints
export const categoryApi = {
  getMenu: () => api.get("/categories/menu"),
};

export const productApi = {
  getAll: (page = 1, pageSize = 12) =>
    api.get(`/products?page=${page}&pageSize=${pageSize}`),
  getById: (id) => api.get(`/products/${id}`),
  getByCategory: (categoryId, page = 1, pageSize = 12) =>
    api.get(
      `/products/category/${categoryId}?page=${page}&pageSize=${pageSize}`
    ),
  getByCategoryAndSubcategory: (
    categoryId,
    subcategoryId,
    page = 1,
    pageSize = 12
  ) =>
    api.get(
      `/products/category/${categoryId}/subcategory/${subcategoryId}?page=${page}&pageSize=${pageSize}`
    ),
};
export const fetchCategories = async () => {
  try {
    const response = await api.get(`/categories/menu`);

    return response.data.data;
  } catch (error) {
    console.error("Error fetching categories:", error);

    return [];
  }
};
export const fetchProductsByCategory = async (category) => {
  try {
    const endpoint =
      category === "all" ? "/products" : `/products/category/${category}`;
    const response = await api.get(`${endpoint}`);
    return response.data.data.products;
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

export const blogApi = {
  getAll: (params) => api.get("/blogs", { params }),
  getBySlug: (slug) => api.get(`/blogs/${slug}`),
  getCategories: () => api.get("/blog-categories"),
};

// General API methods
export const apiService = {
  get: (url, config = {}) => api.get(url, config),
  post: (url, data = {}, config = {}) => api.post(url, data, config),
  put: (url, data = {}, config = {}) => api.put(url, data, config),
  delete: (url, config = {}) => api.delete(url, config),
  upload: (url, formData, config = {}) =>
    api.post(url, formData, {
      ...config,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
};
export default api;

// Add this new function to fetch featured products
export const fetchFeaturedProducts = async (category = null) => {
  try {
    const endpoint =
      category && category !== "all"
        ? `/products/featured?categoryId=${category}`
        : "/products/featured";
    const response = await api.get(endpoint);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching featured products:", error);
    return [];
  }
};

export const companyApi = {
  getInfo: () => api.get("/company/info"),
};
