import { EnvironmentFilled, MailFilled, PhoneFilled } from "@ant-design/icons";
import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { companyApi } from "../../utils/api";

const ContactUs = () => {
  const [companyInfo, setCompanyInfo] = useState(null);

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await companyApi.getInfo();
        setCompanyInfo(response.data.data);
      } catch (error) {
        console.error("Error fetching company info:", error);
      }
    };

    fetchCompanyInfo();
  }, []);

  return (
    <div className="bg-white dark:bg-slate-900 w-full pt-10">
      <div className="container mx-auto py-5">
        <h2 className="font-bold text-4xl text-slate-900 dark:text-white text-center mb-10">
          Contact Us
        </h2>

        <div className="bg-slate-50 dark:bg-slate-900 w-full py-16">
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-10 px-5">
            <div className="flex flex-col gap-10">
              <h2 className="font-bold text-4xl text-slate-700 dark:text-white text-left">
                Get In Touch
              </h2>
              <div className="flex flex-col gap-5">
                <Input placeholder="Full Name" />
                <Input placeholder="Email Address" />
                <Input placeholder="Phone Number" />
                <Input.TextArea rows={4} placeholder="Your Message" />
                <Button type="primary">Send E-Mail</Button>
              </div>
            </div>

            <div>
              <h2 className="font-bold text-4xl text-slate-700 dark:text-white text-left">
                Contact Info
              </h2>
              <div className="mt-10">
                <div className="flex flex-col gap-3 text-xl">
                  {/* <div className="flex items-center gap-3">
                      <PhoneFilled /> {companyInfo.phone}
                    </div>
                    <div className="flex items-center gap-3">
                      <MailFilled /> {companyInfo.email}
                    </div>
                    <div className="flex items-center gap-3">
                      <EnvironmentFilled />
                      {companyInfo.address || ""}
                    </div> */}
                  <div className="flex flex-col gap-3">
                    {/* <h2 className="font-semibold text-xl">
                  {companyInfo.company_name}
                </h2>
                <div className="flex items-center gap-3">
                  <PhoneFilled /> {companyInfo.phone}
                </div>
                <div className="flex items-center gap-3">
                  <MailFilled /> {companyInfo.email}
                </div>
                <div className="flex items-center gap-3">
                  <EnvironmentFilled />
                  {companyInfo.address || ""}
                </div> */}
                    <h2 className="font-semibold text-xl">Mosiur Rahaman</h2>
                    <div className="flex items-center gap-3">
                      <MailFilled /> mosiur@textaj.com
                    </div>
                    <div className="flex items-center gap-3">
                      <PhoneFilled /> 01618709971
                    </div>
                  </div>

                  <div className="flex flex-col gap-3">
                    <h2 className="font-semibold text-xl">CEO</h2>
                    <div className="flex items-center gap-3">
                      <MailFilled /> CEO@textaj.com
                    </div>
                    <div className="flex items-center gap-3">
                      <PhoneFilled /> 01711688621
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {companyInfo &&
      companyInfo?.CompanyAdditionalInfo?.[0]?.google_map_link ? (
        <div className="w-full py-10 bg-white dark:bg-slate-950">
          <div className="py-10 container mx-auto">
            <iframe
              src={companyInfo.CompanyAdditionalInfo[0].google_map_link}
              height="450"
              style={{ border: 0, width: "100%" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ContactUs;
